// @ts-check
window.didomiConfig = window.didomiConfig || {};
window.didomiOnReady = window.didomiOnReady || [];
window.didomiEventListeners = window.didomiEventListeners || [];

export const CUSTOM_PURPOSES = {
  necessary: "nodvandiga-b3JJQXqQ",
  functional: [ "funktionel-t2meYCeq", "functional-GPVKiHEN" ],
  analysisAndDevelopment: "analysutv-BcCq4VEq",
  marketingAndContent: "marknadsfo-G6hNqgqm",
  marketingOtherPlatforms: "cookiesfo-EDDUixVd",
};

/** @typedef {import('../../lib/@types/didomi-types.js').IDidomiObject} Didomi */
/** @typedef {Array<(Didomi: Didomi) => any>} DidomiCallbacks */
/** @typedef {Array<{ event: string, listener: (context: any) => any }>} DidomiEvents */

export const initDidomiPreferences = () => {
  window.addEventListener("click", (e) => {
    if (e.target instanceof HTMLElement && e.target.hasAttribute("data-didomi-preftoggle")) {
      window.didomiOnReady.push((Didomi) => {
        Didomi.notice.show();
      });
    }
  });
};

/**
 * @returns {Promise<Didomi>}
 */
export const waitForDidomi = () => {
  return new Promise((resolve) => {
    if (!window.generika.didomiEnabled) {
      return resolve(window.Didomi);
    }
    window.didomiOnReady.push((Didomi) => resolve(Didomi));
  });
};

/**
 * @param {(Didomi: Didomi) => void} callback
 */
export const onDidomiReady = (callback) => {
  if (!window.generika.didomiEnabled) {
    return callback(window.Didomi);
  }
  window.didomiOnReady.push((Didomi) => callback(Didomi));
};

/**
 * @param {string | string[]} purposeIds
 * @returns {Promise<boolean>}
 */
export const getUserConsentForPurpose = async (purposeIds) => {
  const Didomi = await waitForDidomi();

  const ids = Array.isArray(purposeIds) ? purposeIds : [ purposeIds ];

  const { purposes: enabledPurposes } = Didomi.getCurrentUserStatus();
  const purposes = ids.map((id) => (enabledPurposes[id] || { enabled: false }));

  return purposes.some((purpose) => purpose.enabled);
};

/**
 * @param {string} vendorId
 * @returns {Promise<boolean>}
 */
export const getUserConsentForVendor = async (vendorId) => {
  const Didomi = await waitForDidomi();

  const { vendors: enabledVendors } = Didomi.getCurrentUserStatus();
  const vendor = enabledVendors[vendorId.replace("c:", "")] || { enabled: false };

  return vendor.enabled;
};

/**
 * @param {(Didomi: Didomi) => void} callback
 */
export const onConsentChanged = (callback) => {
  /** @type {DidomiEvents} */(window.didomiEventListeners).push({
    event: "consent.changed",
    listener: () => callback(window.Didomi),
  });
};

/**
 * @description Automatically approve the vendor with all it's purposes
 * @param {string} vendorId
 * @returns {Promise<void>}
 */
export const approveVendorUsage = async (vendorId) => {
  const Didomi = await waitForDidomi();

  const {
    getVendors,
    openCurrentUserStatusTransaction,
  } = Didomi;

  const allVendors = getVendors();

  const vendorItem = allVendors.find((i) => i.id === vendorId);

  if (vendorItem) {
    const transaction = openCurrentUserStatusTransaction();

    transaction.enableVendor(`${vendorItem.id}`.replace("c:", ""));
    transaction.enablePurposes(vendorItem.purposeIds);
    transaction.enablePurposes(vendorItem.legIntPurposeIds);
    transaction.commit();
  }
};
