import HTMLParsedElement from "../html-parsed-element.js";
import {
  approveVendorUsage,
  getUserConsentForVendor,
  onConsentChanged,
  onDidomiReady,
} from "#assets/scripts/didomi.js";

/** @typedef {import('../../../../lib/@types/didomi-types.js').IDidomiObject} Didomi */

const TAG_NAME = "gen-embed-resource";

class Resource extends HTMLParsedElement {
  constructor() {
    super();
    this.checkConsent = this.checkConsent.bind(this);
  }
  /**
   * @description Runs once when the element is parsed by the browser, is in charge of rendering the element based on props etc. Originates from the package https://www.npmjs.com/package/html-parsed-element
   * Modeled after https://github.com/BonnierNews/bnlo-jalla/blob/425a02f86c9da35e2670371ba83b22f4d38cb240/sources/js/custom-elements/resource.js#L6
   */
  parsedCallback() {
    this.overrideCmp = this.getAttribute("data-override-cmp");
    this.vendorId = this.getAttribute("data-vendor-id");
    this.placeholderText = this.getAttribute("data-placeholder-text");
    this.placeholderTextBlocked = this.getAttribute("data-placeholder-text-blocked");
    this.placeholderElement = this.querySelector("embed-resource__placeholder-text");
    this.approveVendorButton = this.querySelector("#approve-vendor");

    if (!this.vendorId) {
      throw new Error("Resource element must have a data-vendor attribute");
    }

    this.vendorIds = this.vendorId.split(",");

    if (!this.iframe || !this.iframe.getAttribute("data-src")) {
      throw new Error("Resource element must have an iframe child element");
    }

    if (this.overrideCmp === "true") {
      this.activate();
    } else {
      onDidomiReady(this.checkConsent);
      onConsentChanged(this.checkConsent);

      if (this.approveVendorButton) {
        this.approveVendorButton.addEventListener("click", async () => {
          await Promise.all(this.vendorIds.map(async (id) => await approveVendorUsage(id)));
        });
      }
    }
  }

  get placeholder() {
    return this.placeholderElement && this.placeholderElement.textContent;
  }

  set placeholder(text) {
    if (this.placeholderElement) {
      this.placeholderElement.textContent = text;
    }
  }

  get iframe() {
    return this.querySelector("iframe");
  }

  /**
   * @description Check if user consented to the vendor to activate/block showing the embed
   */
  async checkConsent() {
    const promises = await Promise.all(this.vendorIds.map(async (id) => await getUserConsentForVendor(id)));

    const hasConsent = promises.some((i) => i);

    if (hasConsent) {
      this.activate();
    } else {
      this.block();
    }
  }

  block() {
    this.removeAttribute("active");
    this.setAttribute("blocked", "");
    this.iframe.src = "about:blank";
    this.placeholder = this.placeholderTextBlocked;
  }

  /**
   * @description Runs when vendor is approved by Didomi/CMP. Loads the iframe.
   */
  activate() {
    this.setAttribute("active", "");
    this.removeAttribute("blocked");
    const iframe = this.iframe;
    this.setupIntersectionObserver(iframe);
    const src = iframe.getAttribute("data-src");
    iframe.src = src;
  }

  /**
   * @param {HTMLIFrameElement} iframe
   */
  setupIntersectionObserver(iframe) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          iframe.contentWindow.postMessage({ isIntersecting: true }, "*");
          observer.unobserve(iframe);
        }
      });
    });
    iframe.onload = () => {
      observer.observe(iframe);
    };
  }
}

export {
  TAG_NAME,
  Resource,
};
