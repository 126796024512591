import { onDidomiReady } from "./didomi.js";

window.dataLayer = window.dataLayer || [];

window.dataLayer.push({
  "gtm.start": new Date().getTime(),
  event: "gtm.js",
});

const EventTriggers = {
  PageLoad: "page load",
  VirtualPageLoad: "virtual page load",
};

const EventNames = { PageView: "page view" };

const pushEvent = (event) => {
  const trackingEvent = { ...event };
  const generika = window.generika || {};
  trackingEvent.content.title = document.title;
  trackingEvent.content.location = document.location.href;
  trackingEvent.content.referrer = document.referrer;
  trackingEvent.content.renderedSize = generika.channel;
  trackingEvent.generika = {
    brand: generika.brand,
    hostname: generika.hostname,
  };

  onDidomiReady(() => {
    window.dataLayer.push(trackingEvent);

    if (trackingEvent.event === EventNames.PageView) {
      window.didomiEventListeners.push({
        event: "consent.changed",
        listener: () => window.dataLayer.push(trackingEvent),
      });
    }
  });
};

const getContent = (meta) => {
  meta = meta || {};
  return {
    type: (meta.tracking ? meta.tracking.contentType : undefined) || "error",
    advertiserName: meta.tracking ? meta.tracking.advertiserName : undefined,
    id: meta.contentId ? { value: meta.contentId, system: "ia" } : undefined,
    trigger: EventTriggers.PageLoad,
    pageNotFound: !meta,
    userHasAccess: true,
    contentAccessStatus: "open",
  };
};

window.addEventListener("message", (event) => {
  if (event.origin !== "https://resources.bonniernews.se") return;
  if (event.data && event.data.service === "kraken" && event.data.dataLayer) {
    const iframeVideoData = Array.isArray(event.data.dataLayer) ? event.data.dataLayer : [ event.data.dataLayer ];
    iframeVideoData.forEach((data) => {
      window.dataLayer.push(data);
    });
  }
});

// General note: the dataLayer will always be populated regardless of didomi-consent status, GTM will only store data when consent is given
function initTracking() {
  const trackingData = (window.generika || {}).trackingData || {};

  // Skip sending event if there is no content or if it's an Article page
  // since it will be automatically handled for Article page in "assets/scripts/tracking/tracking.js"
  if (!trackingData.content || (trackingData.content || {}).type === "article") {
    return;
  }

  pushEvent({
    event: EventNames.PageView,
    ...trackingData,
  });
}

export {
  EventNames,
  EventTriggers,
  getContent,
  initTracking,
  pushEvent,
};
