import {
  CUSTOM_PURPOSES,
  onConsentChanged,
  getUserConsentForPurpose,
  getUserConsentForVendor,
  onDidomiReady,
} from "#assets/scripts/didomi.js";

export const TAG_NAME = "gen-rma";
export class RichMediaAd extends HTMLElement {

  static get cookieName() {
    return "richMediaAdsOk";
  }

  hide() {
    this.setAttribute("hidden", "");
  }

  unveil() {
    this.classList.add("rich-media--unveiled");
  }

  handleEvent(event) {
    try {
      let name = `handle${event.type.charAt(0).toUpperCase()}${event.type.slice(1)}`;
      // Handle `bad:*`-events
      name = name.replace(/Bad:([a-z]{1})/, (match, p1) => p1.toUpperCase());
      return this[name](event);
    } catch (error) {
      return;
    }
  }

  handleCollapse() {
    this.hide();
  }

  handleClick(event) {
    if (event.target.closest(".rich-media__button")) {
      this.hide();
    }
  }

  handleSlotRenderEnded() {
    clearTimeout(this.failsafe);
    this.unveil();
  }
  connectedCallback() {
    this.addEventListener("click", this);
    this.addEventListener("bad:collapse", this);
    this.addEventListener("bad:slotRenderEnded", this);
    this.failsafe = setTimeout(() => {
      this.unveil();
    }, 800);
    const s = new URLSearchParams(window.location.search);
    const isAdDemo = s.has("google_preview") || s.has("ad_demo");
    if (!isAdDemo && (document.cookie.includes("bad-freq-rmp") || !document.cookie.includes(RichMediaAd.cookieName))) {
      this.hide();
    }

    onDidomiReady(() => {
      this.handleFullpageAdConsent();
      onConsentChanged(() => this.handleFullpageAdConsent());
    });
  }

  setFunctionalCookie() {
    const date = new Date();
    date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000)); // 30 days
    document.cookie = `${RichMediaAd.cookieName}=true;expires=${date.toGMTString()}; path=/`;
  }

  revokeFunctionalCookie() {
    document.cookie = `${RichMediaAd.cookieName}=true;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }

  async handleFullpageAdConsent() {
    const adConsent = await getUserConsentForPurpose("select_basic_ads");
    const vendorConsent = await getUserConsentForVendor("bonniernews");
    const functionalAllowed = await getUserConsentForPurpose(CUSTOM_PURPOSES.functional);

    const enableFullpageAd = adConsent && vendorConsent && functionalAllowed;
    if (enableFullpageAd && !document.cookie.includes(RichMediaAd.cookieName)) {
      this.setFunctionalCookie();
    } else if (!enableFullpageAd && document.cookie.includes(RichMediaAd.cookieName)) {
      this.revokeFunctionalCookie();
    }
  }
}
